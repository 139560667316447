<template>
  <div>
     <v-dialog
        v-model="dialog"
        max-width="800"
        persistent
      >
        <v-card>
          <v-card-title><v-icon class="mr-2">mdi-information</v-icon> General Information</v-card-title>
          <v-divider></v-divider>
            <div class="space-20"></div>
            <v-card-text
              >The following is a recommended minimum package that has been provided at the request of your franchisor.

              Some of the policy sections can be increased, decreased or removed depending on your personal requirements, some, however are not able to be amended and are compulsory to meet  your insurance compliance requirements.

              Being able to tailor your policy gives you flexibility whilst still meeting your franchisor compliance requirements.</v-card-text
            >

            <v-card-text style="color:red;"><b>IMPORTANT: Please ensure you carefully read the policy Endorsements & Conditions to ensure compliance.</b></v-card-text>
            <v-card-text>

              By clicking Ok you agree to receive your policy documents electronically.
              <div class="space-10"></div>
          
              <b>NOTE: IF YOU ARE LOCATED IN NSW </b>
              <br>
              <div class="space-10"></div>
                With effect from 1st January 2018 the NSW Government has implemented a scheme for small business where a proportion of Stamp Duty may be refunded to businesses that meet certain criteria. For full details please refer to the Small Business Declaration that will be supplied to you along with other documents relevant to this transaction.

                If you do qualify please return the completed declaration to info@mynfib.com.au and the applicable proportion of Stamp Duty will be refunded to you.
                <div class="space-10"></div>
                <b>Please note, this is an indicative quotation only, and may change depending on your claims history.</b>
          </v-card-text>

           <v-card-actions class="justify-end" >
             <v-btn color="error" @click="confirm">OK</v-btn>
            </v-card-actions>
            <div class="space-20"></div>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
 export default ({
  name:'BeforeYouStart',
  props: {
    dialog:Boolean,
  },
  methods: { 
    confirm() {
      this.$emit(`confirmed`)
    },
  }
  
})
</script>

<style lang="scss" scoped>

</style>