<template>
  <div class="px-5">
    <v-breadcrumbs
      class="pa-0 mt-n5"
      :items="breadcrumbs"
      divider="-"
    ></v-breadcrumbs>
    <div class="space-20"></div>
    <hr />
    <div class="space-20"></div>

    <v-row>
      <v-col cols="12" lg="8">
        <div>
          <v-form v-model="policyValidate" ref="policy-validate">
            <template v-if="item.template_id">
              <v-row>
                <v-col cols="12" lg="6">
                  <template class="policy date">
                    <v-menu
                      v-model="datePickerMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p>Please Select Policy Start Day</p>
                        <template v-if="isTemplateStartDateHead">
                          <v-text-field
                            outlined
                            dense
                            v-model="currentTemplate.start_dt"
                            label="Enter Policy Start Date"
                            prepend-icon="mdi-calendar"
                            disabled
                          ></v-text-field>
                        </template>
                        <template v-else>
                          <v-text-field
                            outlined
                            dense
                            :rules="[
                              (v) => !!v || 'Please Select a Policy Date',
                            ]"
                            v-model="item.start_dt"
                            label="Enter Policy Start Date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="getLatestPolicy"
                            v-on="on"
                          ></v-text-field>
                        </template>
                      </template>
                      <v-date-picker
                        :min="todaysDay"
                        :rules="[(v) => !!v || 'Please Select a Policy Date']"
                        v-model="item.start_dt"
                        @input="datePickerMenu = false"
                        @change="getLatestPolicy"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-col>
                <v-col cols="12" lg="6">
                  <p>Please Select a Situation</p>
                  <v-select
                    :items="situationsList"
                    item-text="nameValue"
                    :loading="selectionLoading"
                    item-value="id"
                    v-model="selectedSituation"
                    :rules="[(v) => !!v || 'Please select a Situation']"
                    outlined
                    clearable
                    @change="situationSelected($event)"
                    dense
                    label="Select Situations"
                  ></v-select>
                </v-col>
              </v-row>

              <template
                class="situations-list"
                v-if="item.situations && item.situations.length > 0"
              >
                <div
                  class="situation-area"
                  v-for="situation in item.situations"
                  :key="situation.id"
                >
                  <h3>Situation - {{ getSituationNameById(situation.id) }}</h3>
                  <div class="space-20"></div>
                  <h3 class="text-center mb-2">PRODUCTS</h3>
                  <div
                    class="products-list"
                    style="border:1px solid rgba(0,0,0,0.1)"
                  >
                    <div class="space-30"></div>
                    <v-row>
                      <span
                        v-for="(product, index) of templateMappedProducts"
                        :key="index"
                      >
                        <v-checkbox
                          @change="
                            toggleProduct(product.product_id, situation.id)
                          "
                          class="ml-5 my-0"
                          v-model="product.product.checked"
                          :label="product.product.name"
                        ></v-checkbox
                      ></span>
                    </v-row>
                  </div>

                  <div class="space-10"></div>

                  <div style="border:1px solid rgba(0,0,0,0.1)">
                    <v-row class="mt-3 mx-2">
                      <h4 style="font-size: 14px;" class="mb-2 ml-4">
                        Documents
                      </h4>
                    </v-row>
                    <v-row class="my-3 mx-2" v-show="showProdDocs">
                      <v-col
                        cols="3"
                        v-for="item in prodDocs"
                        class="text-center"
                        :key="item.id"
                      >
                        <a
                          style="font-size: 14px;"
                          @click.prevent="getfile(item.descr)"
                          href="#"
                          >{{ item.name }}</a
                        >
                      </v-col>
                    </v-row>
                  </div>

                  <template class="sections">
                    <v-card v-if="loading">
                      <v-skeleton-loader
                        v-bind="$attrs"
                        type="table-heading, list-item-two-line, image"
                      ></v-skeleton-loader>
                    </v-card>
                    <template v-else>
                      <div class="space-30"></div>
                      <v-row
                        v-if="
                          situation.products && situation.products.length > 0
                        "
                      >
                        <v-col cols="12">
                          <p
                            class="text-center border-solid-1"
                            :style="franchiseStyle"
                          >
                            <b>Coverage</b>
                          </p>
                          <div class="space-20"></div>
                        </v-col>
                        <!-- <v-col cols="12" lg="6">
                      <p class="text-center light-bg" 
                        :style="franchiseStyle"
                        ><b>Optional Coverage</b></p>
                    </v-col> -->
                      </v-row>

                      <div class="space-20"></div>

                      <div
                        v-if="
                          situation.products && situation.products.length > 0
                        "
                      >
                        <div
                          class="per-product"
                          v-for="product of situation.products"
                          :key="product.id"
                        >
                          <h1>{{ getProductNameById(product.id) }}</h1>
                          <div class="per-section">
                            <v-row class="px-10 mb-5">
                              <v-col cols="12">
                                <div
                                  :class="{
                                    'mandatory-coverage mb-5 py-2':
                                      section.status,
                                  }"
                                  :style="
                                    section.status &&
                                      'border-bottom:1px solid rgba(0,0,0,0.1)'
                                  "
                                  v-for="section in product.sections"
                                  :key="section.id"
                                >
                                  <div v-if="section.status">
                                    <b>{{ section.name }}</b>
                                    <div class="space-20"></div>
                                    <div>
                                      <v-row
                                        v-for="sum in section.sums"
                                        :key="sum.id"
                                      >
                                        <div
                                          v-if="
                                            sum.status &&
                                              !sum.is_mandatory &&
                                              (!isDisabledParentSum(
                                                sum,
                                                section
                                              ) ||
                                                !showSumValue(sum))
                                          "
                                        >
                                          <v-switch
                                            class="ml-4 mt-0"
                                            v-model="sum.taken"
                                            @change="sumUpdated"
                                            dense
                                            :label="
                                              `Would you like to opt in for the ${sum.name} cover?`
                                            "
                                          ></v-switch>
                                        </div>
                                        <v-col
                                          cols="12"
                                          :lg="
                                            sum.sum_type === 'complex_fixed'
                                              ? 11
                                              : 7
                                          "
                                          v-if="sum.status"
                                        >
                                          <v-radio-group
                                            v-if="
                                              sum.sum_type === 'complex_fixed'
                                            "
                                            v-model="sum.amount"
                                            @change="sumUpdated"
                                            row
                                            style="padding-top: 0; margin-top:0"
                                          >
                                            <div
                                              v-for="option in sum.thresholds"
                                              :key="option.thrsh_from"
                                            >
                                              <v-radio
                                                v-if="showOption(option, sum)"
                                                :label="
                                                  option.thrsh_from.toString()
                                                "
                                                :value="option.thrsh_from"
                                              ></v-radio>
                                            </div>
                                          </v-radio-group>
                                          <p
                                            v-if="
                                              sum.sum_type === 'complex_fixed'
                                            "
                                            class="v-messages theme--light"
                                          >
                                            {{ sum.name }}
                                          </p>
                                          <v-slider
                                            v-if="
                                              sum.sum_type !== 'complex_fixed'
                                            "
                                            v-model.number="sum.amount"
                                            persistent-hint
                                            @change="sumUpdated"
                                            :hint="sum.name"
                                            track-color="grey"
                                            :disabled="
                                              isDisabledSum(sum, section) ||
                                                (!sum.is_mandatory &&
                                                  !sum.taken)
                                            "
                                            :step="
                                              sum.sum_params.step
                                                ? sum.sum_params.step
                                                : 1000
                                            "
                                            :min="
                                              sum.sum_params.minimum
                                                ? sum.sum_params.minimum
                                                : 0
                                            "
                                            :max="
                                              sum.sum_params.maximum
                                                ? sum.sum_params.maximum
                                                : 1000000000
                                            "
                                          >
                                          </v-slider>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          lg="4"
                                          v-if="
                                            sum.status &&
                                              sum.sum_type !== 'complex_fixed'
                                          "
                                        >
                                          <v-text-field
                                            v-if="showSumValue(sum)"
                                            dense
                                            outlined
                                            @input="
                                              sumUpdatedOnInput(sum, $event)
                                            "
                                            :disabled="
                                              isDisabledSum(sum, section) ||
                                                (!sum.is_mandatory &&
                                                  !sum.taken)
                                            "
                                            :prepend-icon="
                                              iconDeterminator(sum)
                                            "
                                            :value="sum.amount"
                                            :key="inputKey"
                                          ></v-text-field>
                                          <v-text-field
                                            v-else
                                            dense
                                            outlined
                                            :disabled="!sum.taken"
                                            :readonly="sum.taken"
                                            value="Replacement Value"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col
                                          cols="12"
                                          lg="1"
                                          v-if="sum.status"
                                        >
                                          <v-tooltip
                                            max-width="400"
                                            bottom
                                            :color="
                                              franchiseData.brand_background_color
                                                ? franchiseData.brand_background_color
                                                : 'black'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-icon
                                                :color="
                                                  franchiseData.brand_background_color
                                                    ? franchiseData.brand_background_color
                                                    : 'black'
                                                "
                                                :style="
                                                  `background-color: ${franchiseData.brand_text_color}; border-radius: 50%`
                                                "
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                mdi-information
                                              </v-icon>
                                            </template>
                                            <span v-if="sum.descr == 'None'">{{
                                              sum.name
                                            }}</span>
                                            <span v-else>{{ sum.descr }} </span>
                                          </v-tooltip>
                                        </v-col>
                                      </v-row>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <!-- <v-col cols="12">
                                <div
                                  class="optional-coverage mb-5 py-2"
                                  style="border-bottom:1px solid rgba(0,0,0,0.1)"
                                  v-for="section in filteredCoverage(
                                    product.sections,
                                    'optional'
                                  )"
                                  :key="section.id"
                                >
                                  <b>{{ section.name }}</b>
                                  <div class="space-20"></div>
                                  <v-row
                                    v-for="sum in section.sums"
                                    :key="sum.id"
                                  >
                                    <v-col cols="12" lg="7">
                                      <v-slider
                                        v-model="sum.amount"
                                        persistent-hint
                                        :disabled="isDisabledSum(sum, section)"
                                        :hint="sum.name"
                                        @change="sumUpdated"
                                        track-color="grey"
                                        :step="
                                          sum.sum_params.step
                                            ? sum.sum_params.step
                                            : 10000
                                        "
                                        always-dirty
                                        :min="
                                          sum.sum_params.minimum
                                            ? sum.sum_params.minimum
                                            : 0
                                        "
                                        :max="
                                          sum.sum_params.maximum
                                            ? sum.sum_params.maximum
                                            : 1000000000
                                        "
                                      >
                                      </v-slider>
                                    </v-col>

                                    <v-col cols="12" lg="4">
                                      <v-text-field
                                        dense
                                        @input="sumUpdatedOnInput"
                                        outlined
                                        :disabled="isDisabledSum(sum, section)"
                                        prepend-icon="mdi-currency-usd"
                                        v-model="sum.amount"
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" lg="1">
                                      <v-tooltip
                                        max-width="400"
                                        :color="
                                          franchiseData.brand_background_color
                                            ? franchiseData.brand_background_color
                                            : 'black'
                                        "
                                        bottom
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            :color="
                                              franchiseData.brand_background_color
                                                ? franchiseData.brand_background_color
                                                : 'black'
                                            "
                                            :style="
                                              `background-color: ${franchiseData.brand_text_color}; border-radius: 50%`
                                            "
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            mdi-information
                                          </v-icon>
                                        </template>
                                        <span v-if="sum.descr == 'None'">{{
                                          sum.name
                                        }}</span>
                                        <span v-else>{{ sum.descr }} </span>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-col> -->
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </template>
            <div class="space-30"></div>
          </v-form>
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <template
          class="insurance-coverage"
          v-if="item.situations && item.situations.length > 0"
        >
          <div
            :class="{ 'floater-box': !isSizeSmall }"
            class=" py-0"
            style="background:#E9B93F;border-radius:10px;"
          >
            <div></div>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <p class="mt-5">
                  The figures below show the annual and pro-rata costs. The
                  pro-rata cost is the cost of your policy from the commencement
                  date and your Franchise Group expiry date specified below.
                </p></v-col
              >
            </v-row>
            <v-row class="my-0">
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <template>
                  <div class="ml-15" v-if="isPremiumLoading">
                    <v-progress-circular
                      :size="20"
                      class="my-5"
                      color="black"
                      indeterminate
                    ></v-progress-circular>
                  </div>

                  <h3 class="dollor-text opacity-less" v-else>
                    $ {{ coveragePremiums.annual_cost }}
                  </h3>
                </template>

                <p class="mb-0">Annual Cost, Inc. GST</p>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="1"></v-col>
              <v-col cols="10">
                <template>
                  <div class="ml-15" v-if="isPremiumLoading">
                    <v-progress-circular
                      :size="20"
                      class="my-5"
                      color="black"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <h3 class="dollor-text  opacity-less" v-else>
                    $ {{ coveragePremiums.proRata_cost }}
                  </h3>
                </template>

                <p class="ma-0">Pro Rata Cost, Inc. GST</p>
                <!-- <v-checkbox
                class="ma-0"
                color="error"
                label="Business Insurance, Inc GST"
              ></v-checkbox> -->
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col offset="1" cols="10">
                <p class="ma-0">
                  <strong>Expiry Date of policies</strong>
                </p>
                <p class="ma-0">
                  {{ checkedProductName }} -
                  <span v-if="policyExpiry" class="ma-0">
                    <v-icon small>mdi-clock-check-outline</v-icon>
                    {{ policyExpiry }}</span
                  >
                </p>
                <div class="space-30"></div>
                <v-btn
                  :color="franchiseData.brand_background_color"
                  :style="{ color: franchiseData.brand_text_color }"
                  @click="reviewPolicyClicked"
                  :disabled="isPremiumLoading"
                  ><v-icon class="mr-2" small>mdi-file-document</v-icon>Review
                  Policy Schedule & Buy</v-btn
                >
                <div class="space-20"></div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-col>
    </v-row>
    <Dialog :dialog="dialog" @confirmed="dialog = false" />

    <template v-if="coverageDialog">
      <CoverageDialogs
        :dialog="coverageDialog"
        :prodInfo="{
          template_id: item.template_id,
          product_id,
        }"
        :referralSum="referralSum"
        @confirmed="openPaymanetDialog"
      />
    </template>

    <template v-if="taxDialog">
      <TaxBasDialog
        :dialog="taxDialog"
        :franchiseeId="franchiseeId"
        @confirmed="closeTaxDialog($event)"
      />
    </template>

    <v-dialog v-model="reviewDialog" persistent max-width="1000">
      <v-card>
        <v-card-title class="text-h5">
          Review Policy Schedule
        </v-card-title>
        <v-card-text class="reviewCard">
          <v-card class="text-center mb-2" v-if="showDalogSkeleton">
            <span>Loading...Please Wait</span>
            <v-skeleton-loader
              v-bind="$attrs"
              title="Loading...Please Wait"
              type="table-heading, list-item-two-line, image"
            ></v-skeleton-loader>
          </v-card>
          <iframe
            v-show="!showDalogSkeleton"
            ref="iframe"
            width="100%"
            height="100%"
            frameborder="1"
          >
          </iframe>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="reviewDialog = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="franchiseData.brand_background_color"
            :style="{ color: franchiseData.brand_text_color }"
            :disabled="isBuyNowBtn"
            @click="buyNow"
          >
            Buy Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PaymentDialog
      :dialog="paymentDialog"
      :cost="coveragePremiums.proRata_cost"
      @cancelled="closePaymentDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BaseService from "@/services/base.service.js";
import TemplateService from "@/services/template.service.js";
import PolicyService from "@/services/policy.service.js";
import FranchiseesService from "@/services/franchisees.service.js";
import FranchiseMixin from "@/mixins/franchise-style";
import Dialog from "@/components/franchisees/insurance-coverage/popups/BeforeYouStart";
import PaymentDialog from "@/components/franchisees/insurance-coverage/popups/PaymentDialog";
import { debounce } from "@/helpers/basic_helper";
import CoverageDialogs from "./popups/CoverageDialogs";
import TaxBasDialog from "./popups/TaxBasDialog";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

export default {
  name: "InsuranceCoverage",
  data() {
    return {
      policyValidate: false,
      loading: false,
      isBuyNowBtn: true,
      coverageValidation: {},
      coverageDialog: false,
      coveragePremiums: {},
      policyExpiry: "",
      productChecked: true,
      templateMappedProducts: [],
      templatesList: [],
      currentTemplate: {},
      savedPolicy: {},
      situationsList: [],
      selectionLoading: false,
      item: {
        situations: [],
        template_id: null,
        start_dt: moment().format("YYYY-MM-DD"),
      },
      datePickerMenu: false,
      dialog: false,
      reviewDialog: false,
      isPremiumLoading: true,
      taxDialog: false,
      breadcrumbs: [
        {
          id: 1,
          text: "Customize Insurance",
          href: "#",
        },
        {
          id: 2,
          text: "Step 2",
          href: "#",
        },
      ],
      prodDocs: [],
      showProdDocs: false,
      paymentDialog: false,
      showDalogSkeleton: false,
      policyDetails: {},
      selectedSituation: {},
      referralSum: [],
      inputKey: 0,
      product_id: "",
      subLookUpTemplateId: "",
    };
  },
  components: {
    Dialog,
    CoverageDialogs,
    PaymentDialog,
    TaxBasDialog,
  },
  mixins: [FranchiseMixin],
  computed: {
    ...mapGetters("policies", ["getIsPaymentComplete"]),
    ...mapGetters("franchisee", ["getFranchiseeData"]),

    franchiseeId() {
      return this.$store.state.auth.user.franchisee.id;
    },
    isSizeSmall() {
      if (this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    },
    franchiseStyle() {
      return {
        // background: this.franchiseData.brand_background_color,
        color: this.franchiseData.brand_text_color,
      };
    },
    checkedProductName() {
      let checkedProd = this.templateMappedProducts.map((item) => {
        if (item.product.checked) return item.product.name;
      });

      return checkedProd[0];
    },
    todaysDay() {
      // if (this.franchiseData.id === 297) { // 268 PL franchise local test .. 297 Finn Poke
      //   return new Date(2022, 3, 9).toISOString().slice(0, 10);
      // }
      return new Date().toISOString().slice(0, 10);
    },
    isTemplateStartDateHead() {
      let current_date = moment().format("YYYY-MM-DD");
      if (this.currentTemplate.start_dt) {
        let isAfter = moment(this.currentTemplate.start_dt).isAfter(
          current_date
        );
        return isAfter;
      } else return false;
    },
  },
  async mounted() {
    this.getTaxQuestions();
  },
  methods: {
    ...mapMutations("policies", ["setPolicyDetails", "setIsPaymentComplete"]),
    ...mapMutations("franchisee", ["setSelectedSituation"]),

    showOption(option, sum) {
      if (option.thrsh_from) {
        if (this.getFranchiseeData.cluster_group_sub_lookup_id) {
          let accaMinSum = sum.thresholds.find((item) => item.is_acca_min);

          if (accaMinSum?.is_acca_min) {
            return option.thrsh_from >= accaMinSum.thrsh_from;
          }

          return true;
        }

        return true;
      }

      return false;
    },

    getTaxQuestions() {
      if (this.getFranchiseeData.franchise.cluster_group?.name === "Scheme") {
        if (!this.getFranchiseeData.cluster_group_sub_lookup_id) {
          this.taxDialog = true;
        } else {
          this.subLookUpTemplateId = this.getFranchiseeData.cluster_group_sub_lookup_id;
          this.loadTemplate();
        }
      } else {
        this.loadTemplate();
      }
    },

    closeTaxDialog(taxAnswer) {
      this.taxDialog = false;
      this.subLookUpTemplateId = taxAnswer;
      this.loadTemplate();
    },

    async loadTemplate() {
      await this.getActiveTemplate();
      this.getSituationsList();
      this.dialog = true;
      this.setIsPaymentComplete(false);
    },

    async reviewPolicyClicked() {
      try {
        this.reviewDialog = true;
        this.showDalogSkeleton = true;
        await this.validate(false);

        if (this.policyDetails.id) {
          let res = await PolicyService.getPolicySchedule(
            this.policyDetails.id
          );

          const iFrameEl = this.$refs.iframe;
          const doc = iFrameEl.contentWindow.document;
          doc.open();
          doc.write(res.data);
          doc.close();

          iFrameEl.onload = () => {
            iFrameEl.style.height = doc.body.scrollHeight + 500 + "px";
          };

          this.showDalogSkeleton = false;
          this.isBuyNowBtn = false;
        } else {
          this.reviewDialog = false;
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: InsuranceCoverage.vue ~ line 388 ~ getActiveTemplate ~ error",
          error
        );
        this.$store.dispatch(
          "common/setFlashMsg",
          "Some error occured. Please try again later"
        );
      }
    },
    showSumValue(sum) {
      return (
        sum.sum_params.default !== 0 ||
        sum.sum_params.maximum !== 0 ||
        sum.sum_params.minimum !== 0
      );
    },
    isDisabledParentSum(sum, section) {
      let parentSum = section.sums.find(
        (item) => item.id === sum.dependent_on_sum_id
      );

      let disableFlag = false;

      if (parentSum) {
        if (parentSum.is_mandatory) {
          disableFlag = false;
        } else {
          disableFlag = !parentSum.taken;
        }
      }

      return disableFlag;
    },
    isDisabledSum(sum, section) {
      let isMaxedOut = sum.sum_params.minimum == sum.sum_params.maximum;

      let parentSum = section.sums.find(
        (item) => item.id === sum.dependent_on_sum_id
      );

      let isNotTaken;

      if (parentSum) {
        isNotTaken = !parentSum.taken;
      } else {
        isNotTaken = false;
      }

      return isMaxedOut || isNotTaken;
    },
    getfile(file) {
      window.open(file, "_blank");
    },
    buyNow() {
      this.reviewDialog = false;
      // if (Object.keys(this.savedPolicy).length > 0) {
      this.coverageDialog = true;
      // }
    },
    coverageSaved(savedPolicy) {
      this.savedPolicy = savedPolicy;
      this.coverageValidation.coverage = true;
      this.currentStep = 2;
    },
    premiumUpdated(premium) {
      let totalPremium;
      if (premium.total_prorated_premium > 0) {
        totalPremium = premium.total_prorated_premium.toFixed(2);
      } else {
        totalPremium = premium.total_prorated_premium;
      }

      let totalAnnualPremium =
        premium.total_premium > 0
          ? premium.total_premium.toFixed(2)
          : premium.total_premium;

      this.isCoverageCalc = true;
      let formattedPremium = totalPremium
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let formattedAnnualPremium = totalAnnualPremium
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.$set(this.coveragePremiums, `proRata_cost`, formattedPremium);
      this.$set(this.coveragePremiums, `annual_cost`, formattedAnnualPremium);
    },
    downloadPDF(link, label) {
      axios
        .get(link, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    async getActiveTemplate() {
      let service = new BaseService("templates");
      let params = {
        franchise_id: this.franchiseData.id,
        current: 1,
      };
      try {
        let res = await service.get_all(params);
        let templateId;

        if (this.subLookUpTemplateId) {
          this.currentTemplate = res.data.results.find(
            (item) =>
              item.cluster_group_sub_lookup_id === this.subLookUpTemplateId
          );

          templateId = this.currentTemplate.id;
        } else {
          this.currentTemplate = res.data.results[0];
          templateId = res.data.results[0].id;
        }

        this.$set(this.item, `template_id`, templateId);
      } catch (error) {
        console.error(
          "🚀 ~ file: InsuranceCoverage.vue ~ line 388 ~ getActiveTemplate ~ error",
          error
        );
      }
    },
    filteredCoverage(sections, type) {
      if (sections) {
        if (type == "mandatory") {
          return sections.filter((section) => {
            return section.is_mandatory == null || section.is_mandatory == true;
          });
        } else {
          return sections.filter((section) => section.is_mandatory == false);
        }
      }
    },
    async getLatestPolicy() {
      await this.getPolicyExpiryDate();
      await this.sumUpdated();
    },
    sumUpdated() {
      let data = this.prepareJsonForSaving();
      this.getPremiums(data);
      this.checkReferral(data);
    },
    checkReferral() {
      this.referralSum = [];
      this.item.situations.forEach((situation) => {
        situation.products.forEach((product) => {
          product.sections.forEach((section) => {
            section.sums.forEach((sum) => {
              let taken = false;
              if (section.status && sum.status) {
                if (sum.is_mandatory) {
                  taken = true;
                } else if (
                  !this.isDisabledParentSum(sum, section) ||
                  !this.showSumValue(sum)
                ) {
                  taken = sum.taken;
                }
              }

              if (
                sum.referral_limit &&
                sum.amount >= sum.referral_limit &&
                taken
              ) {
                this.referralSum.push({ id: sum.id, amount: sum.amount });
              }
            });
          });
        });
      });
    },
    getPolicyExpiryDate() {
      let data = this.prepareJsonForSaving("expiry");
      this.$set(data, "fields", "expiry_dt");
      this.savePolicy(data, "expiry");
    },
    getProductNameById(id) {
      let nameObj = this.templateMappedProducts.filter(
        (product) => product.product_id == id
      )[0];

      return nameObj?.name;
    },
    sumUpdatedOnInput: debounce(function(sum, value) {
      let val = parseInt(value);

      sum.sum_params.step = 1;

      let minimum = sum.sum_params.minimum ?? 0;
      let maximum = sum.sum_params.maximum ?? 1000000000;

      if (val >= minimum && val <= maximum) {
        sum.amount = val;
      } else {
        val < minimum
          ? this.$set(sum, "amount", minimum)
          : this.$set(sum, "amount", maximum);
        this.inputKey = this.inputKey + 1;
        this.$store.dispatch(
          "common/setFlashMsg",
          "Entered amount is either too less or too high"
        );
      }
      this.sumUpdated();
    }, 1000),
    getSituationNameById(id) {
      if (id) {
        return this.situationsList.filter((situation) => situation.id == id)[0]
          .name;
      }
      1;
    },
    async validate(isNext = true) {
      this.$refs[`policy-validate`].validate();
      if (this.policyValidate) {
        let policyData = this.prepareJsonForSaving();
        await this.savePolicy(policyData, "", isNext);
      }
      // this.$emit(`coverageSaved`)
    },
    async getPremiums(data) {
      this.isPremiumLoading = true;
      let service = new BaseService("premiums");
      try {
        let res = await service.create(data);
        if (res.data.error_message ?? false) {
          this.$store.dispatch("common/setFlashMsg", res.data.error_message);
        } else {
          this.premiumUpdated(res.data);
          let totalPremium = Number(res.data.total_premium.toFixed(2));
          this.$set(this.item, "amount", totalPremium);
        }
        this.$;
      } catch (error) {
        console.error(
          "🚀 ~ file: InsuranceCoverage.vue ~ line 338 ~ savePolicy ~ error",
          error
        );
        this.premiumUpdated({ total_premium: "..." });
      } finally {
        this.isPremiumLoading = false;
      }
    },
    async savePolicy(data, type, isNext) {
      let service = new BaseService("policies");
      try {
        let res = await service.create(data);
        if (type && type == "expiry") {
          this.$set(this.item, `expiry_date`, res.data.expiry_dt);
          this.$emit(`setExpiryDate`, res.data.expiry_dt);
          this.policyExpiry = res.data.expiry_dt;
        } else {
          res.data.proRataCost = this.coveragePremiums.proRata_cost;
          this.policyDetails = { ...res.data };
          this.setPolicyDetails(res.data);
          if (isNext) this.policyConfirmed(res.data);
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: InsuranceCoverage.vue ~ line 338 ~ savePolicy ~ error",
          error
        );
        this.$store.dispatch(
          "common/setFlashMsg",
          "Some error occured. Please try again later"
        );
      }
    },
    policyConfirmed() {
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      this.$router.push(`/franchisee/customise-insurance/preview-policy`);
    },
    openPaymanetDialog() {
      this.paymentDialog = true;
      this.coverageDialog = false;
    },
    prepareJsonForSaving(type = null) {
      //Changing policy start date if current date is ahead of template_dt
      if (this.isTemplateStartDateHead) {
        this.$set(this.item, "start_dt", this.currentTemplate.start_dt);
      }

      let item = _.cloneDeep(this.item);
      //Temp setting values
      this.$set(item, "status", 2);
      this.$set(item, "expiry_dt", item.expiry_date);
      if (!item.amount) this.$set(item, "amount", false);

      if (!type) {
        let filteredSums = item.situations
          .map((situation) => {
            return situation.products.map((product) => {
              return product.sections.map((section) => {
                return section.sums.map((sum) => {
                  let taken = false;

                  if (section.status && sum.status) {
                    if (
                      sum.is_mandatory &&
                      !this.isDisabledParentSum(sum, section)
                    ) {
                      taken = true;
                    } else if (
                      !this.isDisabledParentSum(sum, section) ||
                      !this.showSumValue(sum)
                    ) {
                      taken = sum.taken;
                    }
                  }

                  return {
                    sum_id: sum.id,
                    sum_taken: taken,
                    sum_type: sum.sum_type,
                    amount: sum.amount,
                  };
                });
              });
            });
          })
          .flat(2);

        let allSums = [].concat(...filteredSums);
        this.$set(item.situations[0].products[0], "sums", allSums);
        this.$delete(item.situations[0].products[0], "sections");
      }
      return item;
    },
    backToHome() {
      this.$router.push(`/mod`);
    },
    async situationSelected(id) {
      this.item.situations = [];
      let template = { id: id, products: [] };
      this.isPremiumLoading = true;
      this.$emit(`setExpiryDate`, "");
      this.item.situations.push(template);
      //Temp calling for launch purpose

      let selectedSituation = this.situationsList.filter(
        (item) => item.id === id
      );

      this.selectedSituation = selectedSituation[0];

      await this.getTemplateMappedProducts();

      await this.toggleProduct(this.templateMappedProducts[0].product_id, id);

      this.sumUpdated();

      this.setSelectedSituation(selectedSituation[0]);

      //The below code is commented as we need to support multiple situation selection after wards
      // ids.map((id) => {
      //   let situationIndex = this.item.situations.findIndex(situation => situation.id == id);
      //   let template = {id: id, products: []}
      //   if(situationIndex < 0) {
      //     this.item.situations.push(template)
      //   }

      //   //if the situation checkbox is unselected situation should be removed need to write the code for it (for multiple situations)
      // });
    },
    async getSituationsList() {
      let filters = {
        template_id: this.currentTemplate.id,
        insured: 0,
      };
      try {
        this.selectionLoading = true;
        let res = await FranchiseesService.getSituationsByFranchisee(
          this.franchiseeId,
          filters
        );

        res.data.results.forEach((item) => {
          item.nameValue = `${item.name} - ${
            item.address.address1
              ? `${item.address.address1.substr(0, 15)}, `
              : ""
          } ${item.address.state.descr}`;
        });

        this.situationsList = res.data.results;

        if (this.situationsList.length === 1) {
          this.situationSelected(this.situationsList[0].id);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.selectionLoading = false;
      }
    },
    async getTemplateMappedProducts() {
      let service = new BaseService(
        `templates/${this.item.template_id}/products`
      );
      let res = await service.get_all();
      if (res) {
        this.templateMappedProducts = res.data.template_products;
      }

      let docService = new BaseService(`product-docs`);

      this.templateMappedProducts.forEach(async (item) => {
        item.product.checked = true;
        // if (
        //   item.product.name === "Business Pack" ||
        //   item.product.name === "Trades Pack"
        // ) {
        //   item.product.checked = true;
        // }

        let res = await docService.get_all({ product_id: item.product_id });

        if (res.data) {
          let newDocs = res.data.results.sort((a, b) => {
            if (a.product?.id && b.product?.id) {
              return a.rank - b.rank;
            } else if (a.product?.id) {
              return -1;
            } else if (b.product?.id) {
              return 1;
            } else {
              return a.rank - b.rank;
            }
          });

          this.prodDocs = [...newDocs];
        }
      });
      this.showProdDocs = true;
    },
    async fetchProductSections(product_id, situation_id, situation_index) {
      this.loading = true;
      let product = {
        template_id: this.item.template_id,
        product_id: product_id,
        franchiseeId: this.franchiseeId,
      };
      let res = await TemplateService.get_product_sections_with_valid_sums(
        product
      );

      if (res) {
        let productIndex = this.item.situations[
          situation_index
        ].products.findIndex((product) => product.id == product_id);

        let finalRes =
          res.data.sections &&
          res.data.sections.map((section) => {
            let sectionVal = section.section;
            sectionVal.sums.forEach((sumVal) => {
              if (sumVal.franchisee_sum_amount) {
                sumVal.sum_params.step = 1;
                sumVal.amount = sumVal.franchisee_sum_amount;
              } else {
                sumVal.amount = sumVal.sum_params.default ?? sumVal.amount;
              }
            });
            return sectionVal;
          });

        finalRes.sort((a, b) => a.rank - b.rank);

        this.$set(
          this.item.situations[situation_index].products[productIndex],
          "sections",
          finalRes
        );
        this.loading = false;
      }
    },
    async toggleProduct(product_id, situation_id) {
      this.product_id = product_id;
      let currentSituationIndex = this.item.situations.findIndex(
        (situation) => situation.id == situation_id
      );

      let productIndex = this.item.situations[
        currentSituationIndex
      ].products.findIndex((product) => product.id == product_id);

      if (productIndex < 0) {
        this.item.situations[currentSituationIndex].products.push({
          id: product_id,
        });
        await this.fetchProductSections(
          product_id,
          situation_id,
          currentSituationIndex
        );
        this.getPolicyExpiryDate();
      } else {
        this.item.situations[currentSituationIndex].products.splice(
          productIndex,
          1
        );

        this.isPremiumLoading = true;
        this.$emit(`setExpiryDate`, "");
      }
    },
    closePaymentDialog() {
      this.paymentDialog = false;

      if (this.getIsPaymentComplete) {
        this.$router.push({ name: "details" });
      }
    },
    iconDeterminator(sum) {
      if (
        sum.sum_type === "basic" &&
        sum.sum_params.maximum === 100 &&
        !sum.sum_params.minimum
      ) {
        return "mdi-percent-outline";
      }
      return "mdi-currency-usd";
    },
  },
};
</script>

<style lang="scss" scoped>
.dollor-text {
  font-size: 24px;
}

.opacity-less {
  opacity: 0.8;
}

.light-bg {
  background: rgba(0, 0, 0, 0.05);
  padding: 5px 0px;
}

.floater-box {
  position: fixed;
  top: 185px;
  width: 400px;
  font-size: 14px;
}

.reviewCard {
  max-height: 60vh;
  overflow-x: auto;
}

.policyBox {
  border: 1px black solid;
  padding: 0 3rem;
}

.border-solid-1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
