<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-toolbar flat dark :color="franchiseData.brand_background_color">
        <v-toolbar-title :style="{ color: franchiseData.brand_text_color }">
          TAX/BAS question
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card v-if="clusterLoading">
        <v-skeleton-loader
          v-bind="$attrs"
          type="table-heading, list-item-two-line, image"
        ></v-skeleton-loader>
      </v-card>
      <v-card v-else>
        <div class="space-30"></div>
        <v-card-text>Please answer the following questions: </v-card-text>

        <v-card-text class="pb-0">What type of agent are you?</v-card-text>
        <v-card-text>
          <template>
            <v-container class="px-0" fluid>
              <v-radio-group v-model="taxAnswer">
                <v-radio
                  v-for="option in taxOptions"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </template>
        </v-card-text>

        <v-card-text v-if="taxAnswer" class="pb-0"
          >Are you a member of the ACCA?</v-card-text
        >
        <v-card-text v-if="taxAnswer">
          <template>
            <v-container class="px-0" fluid>
              <v-radio-group v-model="accaAnswer">
                <v-radio
                  v-for="option in accaOptions"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </template>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            :style="{ color: franchiseData.brand_text_color }"
            :color="franchiseData.brand_background_color"
            :disabled="taxAnswer === '' || accaAnswer === ''"
            :loading="buttonLoading"
            @click="submit()"
            >Submit</v-btn
          >
        </v-card-actions>
        <div class="space-20"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseService from "@/services/base.service.js";
import FranchiseMixin from "@/mixins/franchise-style";

export default {
  name: "TaxBasDialog",
  mixins: [FranchiseMixin],
  props: {
    dialog: Boolean,
    franchiseeId: Number,
  },
  data() {
    return {
      taxAnswer: "",
      accaAnswer: "",
      clusterLoading: false,
      buttonLoading: false,
      taxOptions: [
        { name: "Tax", id: 1 },
        { name: "Bas", id: 2 },
      ],
      accaOptions: [
        { name: "Yes", id: 1 },
        { name: "No", id: 0 },
      ],
    };
  },
  mounted() {
    this.getSchemeId();
  },
  methods: {
    async getSchemeId() {
      let service = new BaseService("lookups");
      let res = null;

      this.clusterLoading = true;
      try {
        res = await service.get_all({
          type_name: "Cluster Group",
        });

        let schemeId = res.data.results.find((item) => item.name === "Scheme")
          .id;

        this.getTaxOptions(schemeId);
      } catch (e) {
        this.$store.dispatch("common/setFlashMsg", res.msg);
      } finally {
        this.clusterLoading = false;
      }
    },

    async getTaxOptions(schemeId) {
      let service = new BaseService("sub-lookups");
      let res = null;

      this.clusterLoading = true;
      try {
        res = await service.get_all({
          lookup_id: schemeId,
        });

        this.taxOptions = res.data.results;
      } catch (e) {
        this.$store.dispatch("common/setFlashMsg", res.msg);
      } finally {
        this.clusterLoading = false;
      }
    },

    async submit() {
      let service = new BaseService("franchisees");

      this.buttonLoading = true;
      try {
        await service.update({
          cluster_group_sub_lookup_id: this.taxAnswer,
          ACCA: this.accaAnswer,
          id: this.franchiseeId,
        });
      } catch (e) {
        this.$store.dispatch("common/setFlashMsg", "Error saving response");
      } finally {
        this.buttonLoading = false;
        this.$emit(`confirmed`, this.taxAnswer);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
